import { app } from "js/namespaces";
import React, { Component } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import styled from "styled-components";

import { AppView, LibraryView, PresentationFilters } from "common/constants";
import TrackView from "js/app/TrackView";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { CreatePresentationDialog } from "js/editor/CreatePresentation/CreatePresentationDialog";
import DocumentEditor from "js/editor/DocumentEditor/DocumentEditor";
import PresentationEditor from "js/editor/PresentationEditor/PresentationEditor";
import SharedSlideEditor from "js/editor/PresentationEditor/SharedSlideEditor";
import PresentationLibrary from "js/editor/PresentationLibrary/PresentationLibrary";
import ThemeEditor from "js/editor/ThemeEditor/ThemeEditor";
import Spinner from "js/react/components/Spinner";
import { browserHistory } from "js/react/history";
import { renderReactDialog } from "js/react/renderReactRoot";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import VerifyEmailDialog from "js/react/views/MarketingDialogs/VerifyEmailDialog";
import UserOptionsContainer from "js/react/views/UserOptions/UserOptionsContainer";

const logger = getLogger(LogGroup.APP);

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const OfflineBannerContainer = styled.div`
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, .3);
  z-index: 10000;
  position: absolute;
  cursor: wait;

  >div {
    width: 100%;
    height: 50px;
    background: orangered;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
  }
`;

const EmailVerificationBanner = styled.div`
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  background: #cb3d0c;
  color: #fefefe;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

class AppContainer extends Component {
    state = {
        isInitialized: false
    }

    componentDidMount() {
        if (app.isConstrained) {
            window.document.body.classList.add("is_mobile");
        }

        window.addEventListener("keydown", this.handleKeyDown);

        this.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        const { firebaseUser } = this.props;

        if (prevProps.firebaseUser !== firebaseUser) {
            // Re-initialize with new user
            this.initialize();
        }
    }

    componentWillUnmount() {
        if (app.isConstrained) {
            window.document.body.classList.remove("is_mobile");
        }

        window.removeEventListener("keydown", this.handleKeyDown);
    }

    async initialize() {
        const { firebaseUser } = this.props;

        logger.info("[AppContainer] initialize()", { uid: firebaseUser?.uid });

        try {
            const hasBeenInitialized = AppController.isInitialized;
            if (hasBeenInitialized && AppController.firebaseUser === firebaseUser) {
                this.setState({ isInitialized: true });
                return;
            }

            this.setState({ isInitialized: false });

            await AppController.initialize(firebaseUser);

            this.setState({ isInitialized: true });
        } catch (err) {
            logger.error(err, "[AppContainer] initialize() failed");
        }
    }

    handleVerifyEmailClick = () => {
        renderReactDialog(VerifyEmailDialog);
    }

    navigateBack = () => {
        browserHistory.goBack();
    }

    render() {
        const { isConstrained, isAccountVerified, isConnected } = this.props;
        const { isInitialized } = this.state;

        if (!isInitialized) {
            return <Container><Spinner /></Container>;
        }

        return (
            <Container className={isConstrained ? "is_mobile" : ""}>
                <Router history={browserHistory}>
                    <Switch>
                        <Route exact path={"/:id([A-Za-z0-9_-]{20})/:slideId([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})"}>
                            {({ match: { params: { id, slideId } } }) => (
                                <TrackView view={AppView.PRESENTATION_EDITOR}>
                                    <PresentationEditor presentationId={id} initialSlideId={slideId} isSingleSlideEditor={false} />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/:id([A-Za-z0-9_-]{20})/:slideId([A-Za-z0-9_-]{20})"}>
                            {({ match: { params: { id, slideId } } }) => (
                                <TrackView view={AppView.PRESENTATION_EDITOR}>
                                    <PresentationEditor presentationId={id} initialSlideId={slideId} isSingleSlideEditor={false} />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/:id([A-Za-z0-9_-]{20})/:slideIndex?"} >
                            {({ match: { params: { id, slideIndex } } }) => (
                                <TrackView view={AppView.PRESENTATION_EDITOR}>
                                    <PresentationEditor presentationId={id} initialSlideIndex={parseInt(slideIndex ?? 1) - 1} isSingleSlideEditor={false} />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/:id([A-Za-z0-9_-]{20})/settings/:pane?"} >
                            {({ match: { params: { id, pane } } }) => (
                                <TrackView view={AppView.PRESENTATION_SETTINGS}>
                                    <PresentationEditor presentationId={id} initialSlideIndex={1} isSingleSlideEditor={false} renderSettings={true} pane={pane} />
                                </TrackView>
                            )}
                        </Route>
                        <Route path={"/document/:documentId"} >
                            {({ match: { params: { documentId } } }) => (
                                <TrackView view={AppView.DOCUMENT_EDITOR}>
                                    <DocumentEditor documentId={documentId} />
                                </TrackView>
                            )}
                        </Route>
                        <Route path={"/slide/:resourceType/:slideId?"} >
                            {({ match: { params: { slideId, resourceType } } }) => (
                                <TrackView view={AppView.SLIDE_EDITOR}>
                                    <SharedSlideEditor slideId={slideId} resourceType={resourceType} onExit={this.navigateBack} />
                                </TrackView>
                            )}
                        </Route>
                        <Route path={`/library/${PresentationFilters.TEAM_FOLDER}/:folderId/provide_access/:teamMemberId`}>
                            {({ match: { params: { folderId, teamMemberId } } }) => (
                                <TrackView view={AppView.LIBRARY}>
                                    <PresentationLibrary view={LibraryView.LIBRARY} pane={PresentationFilters.TEAM_FOLDER} folderId={folderId}
                                        teamMemberId={teamMemberId} provideAccess />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/library/:pane?/:folderId?/:subFolderId?"} >
                            {({ match: { params: { pane, folderId, subFolderId } } }) => (
                                AppController.isPersonalWorkspace() && pane === PresentationFilters.TEAM_FOLDER ? (
                                    <TrackView view={AppView.LIBRARY}>
                                        <PresentationLibrary view={LibraryView.LIBRARY} pane={null} folderId={null} subFolderId={null} />
                                    </TrackView>
                                ) : (
                                    <TrackView view={AppView.LIBRARY}>
                                        <PresentationLibrary view={LibraryView.LIBRARY} pane={pane} folderId={folderId} subFolderId={subFolderId} />
                                    </TrackView>
                                )
                            )}
                        </Route>
                        <Route exact path={"/team-resources/:pane?"} >
                            {({ match: { params: { pane } } }) => (
                                AppController.isPersonalWorkspace() || AppController.isUserTeamMember() ? (
                                    <TrackView view={AppView.MY_RESOURCES}>
                                        <PresentationLibrary view={LibraryView.MY_RESOURCES} />
                                    </TrackView>
                                ) : (
                                    <TrackView view={AppView.TEAM_RESOURCES}>
                                        <PresentationLibrary view={LibraryView.TEAM_RESOURCES} pane={pane} />
                                    </TrackView>
                                )
                            )}
                        </Route>
                        <Route exact path={"/my-resources/:pane?"} >
                            {({ match: { params: { pane } } }) => (
                                <TrackView view={AppView.MY_RESOURCES}>
                                    <PresentationLibrary view={LibraryView.MY_RESOURCES} pane={pane} />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/theme/:themeOrPresentationId"} >
                            {({ location, match: { params: { themeOrPresentationId } } }) => {
                                const query = new URLSearchParams(location.search);
                                return (
                                    <TrackView view={AppView.THEME_EDITOR}>
                                        <ThemeEditor themeOrPresentationId={themeOrPresentationId} returnPath={query.get("return")} />
                                    </TrackView>
                                );
                            }}
                        </Route>
                        <Route exact path={"/create-presentation/:pane?"} >
                            {({ location: { state }, match: { params: { pane } } }) => (
                                <TrackView view={AppView.CREATE_PRESENTATION}>
                                    <CreatePresentationDialog
                                        isTeamTemplate={!!state?.isTeamTemplate}
                                        libraryFilter={state?.libraryFilter}
                                        pane={pane}
                                    />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/account/:pane?"} >
                            {({ match: { params: { pane } } }) => (
                                <TrackView view={AppView.ACCOUNT}>
                                    <UserOptionsContainer pane={pane} />
                                </TrackView>
                            )}
                        </Route>
                        <Route exact path={"/"}>
                            <TrackView view={AppView.LIBRARY}>
                                <PresentationLibrary view={LibraryView.LIBRARY} />
                            </TrackView>
                        </Route>
                        <Route path="*">
                            <Redirect to="/?" />
                        </Route>
                    </Switch>
                </Router>
                {!isAccountVerified &&
                    <EmailVerificationBanner onClick={this.handleVerifyEmailClick}>
                        We need to validate your email - Click to verify your account.
                    </EmailVerificationBanner>
                }
                {!isConnected &&
                    <OfflineBannerContainer>
                        <div>
                            You are no longer connected to Beautiful.ai. Please check your network status.
                        </div>
                    </OfflineBannerContainer>
                }
            </Container>
        );
    }
}

export default AppController.withState(withFirebaseUser(AppContainer));
